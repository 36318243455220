<template>
  <div>
    <b-form-select
      class="dropdown_country"
      v-model="selected"
      :options="$store.state.list_countries"
      @input="updateCountry"
    ></b-form-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: "World",
    };
  },
  methods: {
    /* Commit selected country to local storage */
    updateCountry() {
      this.$store.commit("updateCountry", this.selected);
    },
  },

  created() {
    this.updateCountry();
  },
};
</script>

<style lang="scss" scoped></style>
