<template>
  <!-- DATA VISUALIZATION SECTION -->
  <section id="visualization">
    <b-container>
      <b-row>
        <b-col md="4">
          <KPICountry v-if="kpi.relative_change" :values="kpi" :unit="biomass_data.unit"></KPICountry>
        </b-col>
        <b-col md="8">
          <h3 class="row_title" data-aos="fade-up" data-aos-duration="800">Biomass Absolute Carbon Stocks</h3>
          <p class="align_center" style="font-size: 0.8rem">from {{ $store.state.timerange.readable_date }}</p>
          <p style="text-align: center">
            <b-form-checkbox class="switch_yearly" switch size="lg" v-model="display_yearly">Compare yearly</b-form-checkbox>
          </p>

          <OverviewChart v-if="overview_chart_data.length > 0 && !display_yearly" :values="overview_chart_data" :unit="biomass_data.unit"> </OverviewChart>
          <YearlyChart :key="$store.state.country" v-if="Object.keys(yearly_chart_data).length > 0 && display_yearly" :values="yearly_chart_data" :unit="biomass_data.unit"></YearlyChart>
          <p class="help">
            <b-icon icon="question-circle-fill" aria-hidden="true"></b-icon>
            The chart above shows the carbon stocks at country-level per quarter. You can select a country with the filter above. You can compare the stocks year per year in a more friendly way using the switch button above.
          </p>
          <p class="mention" style="padding">
            <b-icon icon="exclamation-circle-fill" aria-hidden="true"></b-icon>
            Last year, the European Space Agency (ESA) made changes to the way it processes SMOS data. This data is the key input to the Biomass Carbon Monitor, and we are currently adjusting our algorithms to the new ESA methodology. This effort is
            well advanced, and in the coming weeks we will update the site with the missing values from the third quarter of 2021 onward.
          </p>
        </b-col>
      </b-row>
    </b-container>

    <div class="sep-30"></div>
  </section>
</template>

<script>
import { BiomassData } from "@/classes/BiomassData.js";
import { Countries } from "@/classes/Countries.js";
import KPICountry from "@/components/visualization/KPICountry.vue";
import OverviewChart from "@/components/visualization/OverviewChart.vue";
import YearlyChart from "@/components/visualization/YearlyChart.vue";
export default {
  components: {
    KPICountry,
    OverviewChart,
    YearlyChart,
  },
  data() {
    return {
      biomass_data: new BiomassData(),
      kpi: {},
      overview_chart_data: [],
      yearly_chart_data: [],
      display_yearly: false,
      countries: new Countries(),
    };
  },
  watch: {
    "$store.state.country": function () {
      this.getVisualizationData();
    },
    "$store.state.timerange.readable_date": function () {
      this.getVisualizationData();
    },
  },
  methods: {
    /* Get data to render KPI and Graphs components 
    Called when data is loaded or when filters (country and timespan) change 
    */
    getVisualizationData() {
      this.getKPI();
      this.getOverviewChartData();
      this.getYearlyChartData();
    },

    getKPI() {
      this.kpi = this.biomass_data.getKPI(this.$store.state.country, this.$store.state.timerange.inbound_date, this.$store.state.timerange.outbound_date, this.$store.state.timerange.timespan);
    },
    getOverviewChartData() {
      this.overview_chart_data = this.biomass_data.getOverviewChartData(this.$store.state.country, this.$store.state.timerange.inbound_date, this.$store.state.timerange.outbound_date);
    },
    getYearlyChartData() {
      this.yearly_chart_data = this.biomass_data.getYearlyChartData(this.$store.state.country, this.$store.state.timerange.inbound_date, this.$store.state.timerange.outbound_date);
    },

    loadBiomassGlobal() {
      var _this = this;
      this.$papa.parse("/tabular/biomass_aggregated_world.csv", {
        download: true,
        header: true,
        skipEmptyLines: true,
        complete: function (response) {
          _this.biomass_data.biomass_aggregated_global = response.data;
          if (_this.biomass_data.smos_coverage_country.length > 0) {
            _this.getVisualizationData();
            _this.$forceUpdate();
          }
        },
      });
    },
    /* Fetch Biomass data per country as CSV and parse to JSON */
    loadBiomassByCountry() {
      var _this = this;
      this.$papa.parse("/tabular/biomass_aggregated_country.csv", {
        download: true,
        header: true,
        skipEmptyLines: true,
        complete: function (response) {
          _this.biomass_data.biomass_aggregated_country = response.data;
        },
      });
    },
    /* Fetch Coverage data as CSV and parse to JSON */
    loadCoverage() {
      var _this = this;
      this.$papa.parse("/tabular/SMOS_country_coverage.csv", {
        download: true,
        header: true,
        skipEmptyLines: true,
        complete: function (response) {
          _this.$store.commit("updateListCountries", _this.countries.buildFromTabularData(response.data));
          _this.biomass_data.smos_coverage_country = response.data;
          if (_this.biomass_data.biomass_aggregated_global.length > 0) {
            _this.getVisualizationData();
            _this.$forceUpdate();
          }
        },
      });
    },
  },
  created() {
    this.loadCoverage();
    this.loadBiomassGlobal();
    this.loadBiomassByCountry();
  },
};
</script>

<style lang="scss"></style>
