var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"kpi"}},[_c('b-container',[_c('b-row',[_c('b-col',[_c('h3',{staticClass:"row_title",attrs:{"data-aos":"fade-up","data-aos-duration":"800"}},[_vm._v(" Biomass Carbon Stocks ")])])],1),_c('b-row',[_c('b-col',[_c('p',{staticClass:"help"},[_c('b-icon',{attrs:{"icon":"question-circle-fill","aria-hidden":"true"}}),_vm._v(" The metrics below show the above-ground biomass relative and absolute changes between two quarters. You can adjust the time range and the country selected with the filters above. The relative change per year is computed as the relative change divided by the number of years (e.g. 1.5 for 1 year and half). The coverage indicates the averaged area of the country covered with valid pixels. ")],1),_c('p',{staticClass:"align_center",staticStyle:{"font-size":"0.8rem"}},[_vm._v(" from "+_vm._s(_vm.$store.state.timerange.readable_date)+" ")])])],1),_c('b-row',{attrs:{"data-aos":"fade","data-aos-duration":"1000"}},[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"kpi_card",class:[
            _vm.values.relative_change.includes('+')
              ? 'kpi_card_positive'
              : 'kpi_card_negative' ]},[_c('p',{staticClass:"kpi_value"},[_vm._v(_vm._s(_vm.values.relative_change))]),_c('p',{staticClass:"kpi_desc"},[_vm._v("Relative Change")])])]),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"kpi_card",class:[
            _vm.values.relative_change_per_year.includes('+')
              ? 'kpi_card_positive'
              : 'kpi_card_negative' ]},[_c('p',{staticClass:"kpi_value"},[_vm._v(_vm._s(_vm.values.relative_change_per_year))]),_c('p',{staticClass:"kpi_desc"},[_vm._v("Relative change per year")])])]),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"kpi_card",class:[
            _vm.values.change.includes('+')
              ? 'kpi_card_positive'
              : 'kpi_card_negative' ]},[_c('p',{staticClass:"kpi_value"},[_vm._v(_vm._s(_vm.values.change)+" "+_vm._s(_vm.unit))]),_c('p',{staticClass:"kpi_desc"},[_vm._v("Change")])])]),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"kpi_card"},[_c('p',{staticClass:"kpi_value"},[_vm._v(_vm._s(_vm.values.coverage))]),_c('p',{staticClass:"kpi_desc"},[_vm._v("Coverage")])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }