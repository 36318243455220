<template>
  <div id="app">
    <Navbar :scrolled="pageScrolled"></Navbar>
    <router-view />
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";

export default {
  components: { Navbar },
  data() {
    return {
      pageScrolled: false,
    };
  },
  methods: {
    onScroll(e) {
      this.pageScrolled = e.target.documentElement.scrollTop > 20;
    },
  },
  created: function () {
    if (this.$route.name === "Home") {
      document.title = "Biomass Carbon Monitor | Home";
    } else if (this.$route.name === "WhoWeAre") {
      document.title = "Biomass Carbon Monitor | Who We Are";
    } else if (this.$route.name === "Methods") {
      document.title = "Biomass Carbon Monitor | Methods";
    }
    window.addEventListener("scroll", this.onScroll);
  },
  destroyed: function () {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,700;1,400&display=swap");
@import "./assets/css/global.scss";
</style>
