<template>
  <!-- HERO HEADER SECTION WITH BACKGROUND VIDEO -->
  <section id="hero">
    <div class="overlay"></div>
    <video
      playsinline="playsinline"
      autoplay="autoplay"
      muted="muted"
      loop="loop"
    >
      <source src="../assets/video/hero_background.mp4" type="video/mp4" />
    </video>

    <!-- The header content -->
    <div class="container h-100">
      <div class="d-flex h-100 text-center align-items-center">
        <div class="w-100 text-white">
          <h1 class="hero_title" data-aos="fade-down" data-aos-duration="800">
            INRAE, LSCE and Kayrros
          </h1>
          <p class="hero_subtitle" data-aos="fade" data-aos-duration="800">
            present the Biomass Carbon Monitor
          </p>
          <p class="hero_icon" data-aos="fade-up" data-aos-duration="800">
            <a href="#partners_banner">
              <span class="border_icon"
                ><b-icon
                  class="hero_chevron"
                  icon="chevron-down"
                  aria-hidden="true"
                  scale="3"
                  shift-v="-2"
                ></b-icon>
              </span>
            </a>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss"></style>
