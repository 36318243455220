<template>
  <!-- WORLD MAP SECTION -->
  <section id="world_map">
    <b-container class="world_map_container">
      <b-row>
        <b-col md="12">
          <img
            v-if="imgLoaded"
            class="img"
            :src="urlImg"
            alt="World map"
            @load="imgLoad(true)"
            @error="imgLoad(false)"
          />
          <div v-else style="margin: 40px 0">
            <b-alert show variant="danger"
              >Unfortunately we couldn't load the map for this specific
              timerange. Please try changing it with the filter above.</b-alert
            >
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      urlImg: "/maps/biomass_map2011-Q1_2021-Q2.png",
      imgLoaded: true,
    };
  },
  watch: {
    "$store.state.timerange.inbound_date": function () {
      this.reloadImage();
    },
    "$store.state.timerange.outbound_date": function () {
      this.reloadImage();
    },
  },

  methods: {
    reloadImage() {
      this.imgLoaded = true;
      if (
        this.$store.state.timerange.inbound_date !== "" &&
        this.$store.state.timerange.outbound_date !== ""
      ) {
        this.urlImg =
          "/maps/biomass_map" +
          this.$store.state.timerange.inbound_date +
          "_" +
          this.$store.state.timerange.outbound_date +
          ".png";
      }
    },
    imgLoad(status) {
      this.imgLoaded = status;
      this.$forceUpdate;
    },
  },
};
</script>

<style lang="scss"></style>
