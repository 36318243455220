<template>
  <section
    id="header"
    v-bind:class="[$route.name === 'WhoWeAre' ? 'header_team' : '']"
  >
    <b-container>
      <b-row>
        <b-col>
          <h1 class="hero_title" data-aos="fade-down" data-aos-duration="1000">
            {{ title }}
          </h1>

          <a href="/">
            <b-button
              class="hero_button"
              data-aos="fade-up"
              data-aos-duration="1000"
              >Go back to homepage</b-button
            >
          </a>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      title: "",
    };
  },
  mounted() {
    if (this.$route.name === "WhoWeAre") {
      this.title = "Our Team";
    } else if (this.$route.name === "Methods") {
      this.title = "Our methods";
    }
  },
};
</script>

<style lang="scss"></style>
