<template>
  <div class="content">
    <Header></Header>
    <section id="our-team">
      <b-container>
        <b-row>
          <b-col md="6" v-for="(member, index) in team_members" :key="index">
            <MemberCard :member="member"></MemberCard>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import MemberCard from "@/components/MemberCard.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: { Header, MemberCard, Footer },
  data() {
    return {
      team_members: [
        {
          name: "Jean-Pierre Wigneron",
          desc: "Researcher at INRAE Bordeaux, in the field of microwave remote sensing applied to monitoring the \
                         terrestrial water and carbon cycle.",
          link: "https://scholar.google.fr/citations?user=Hfo-3FcAAAAJ&hl=fr",
          img: "id_jp_wigneron_bw.png",
        },
        {
          name: "Philippe Ciais",
          desc: "Researcher at the Laboratoire des Sciences du Climat et de l’Environnement, near Paris in \
                         France, working on the global terrestrial carbon cycle perturbed by human activities and climate \
                          change.",
          link: "https://scholar.google.com/citations?user=PJhqfAoAAAAJ&hl=en&oi=ao",
          img: "id_philippe_ciais_bw.png",
        },
        {
          name: "Frédéric Frappart",
          desc: "Researcher at LEGOS in the field of remote sensing applied to the monitoring the terrestrial \
                         water and carbon cycle.",
          link: "https://scholar.google.com/citations?user=fybRvZAAAAAJ&hl=fr",
          img: "id_frederic_frappart_bw.png",
        },
        {
          name: "Hui Yang",
          desc: "Post-doc at the Max Planck Institute for Biogeochemistry, Jena, Germany, working on \
                         vegetation dynamics, and terrestrial carbon cycle.",
          link: "",
          img: "id_hui_yang_bw.png",
        },
        {
          name: "Lei Fan",
          desc: "Researcher at Southwest University in China, in the field of microwave remote sensing applied \
                         to monitoring the terrestrial water and carbon cycle.",
          link: "https://scholar.google.com/citations?user=b7X6JBMAAAAJ&hl=zh-CN&oi=sra",
          img: "id_lei_fan_bw.png",
        },
        {
          name: "Xiangming Xiao",
          desc: "Researcher at the University of Oklahoma, Norman, Oklahoma, USA, working on remote sensing, \
                         land use and land cover changes, agriculture,  forestry, and terrestrial carbon and water \
                          cycles.",
          link: "https://scholar.google.com/citations?hl=en&user=71350TcAAAAJ&view_op=list_works",
          img: "id_xiangming_xiao_bw.png",
        },
        {
          name: "Yuanwei Qin",
          desc: "Researcher at the University of Oklahoma, Norman, Oklahoma, USA, working on remote sensing, \
                         land cover and land use change, and terrestrial carbon cycle.",
          link: "https://scholar.google.com/citations?user=L6cvqjMAAAAJ&hl=en",
          img: "id_yuanwei_qin_bw.png",
        },
        {
          name: "Xiaojun Li",
          desc: "P.h.D student supervised by Dr. Jean-Pierre Wigneron to work on the topic of “Improvement of \
                         the SMOS-IC retrievals of soil moisture and vegetation L-VOD index” at the INRAE, Bordeaux.",
          link: "",
          img: "id_xiaojun_li_bw.jpg",
        },
        {
          name: "Martin Brandt",
          desc: "UCPH, Department of Geosciences and Natural Resource Management.",
          link: "https://scholar.google.com/citations?user=2cfq6tcAAAAJ&hl=en",
          img: "id_martin_brandt_bw.png",
        },

        {
          name: "Rasmus Fensholt",
          desc: "Professor of Geography, Dept of Geoscience and Natural Resource Management, \
                        University of Copenhagen.",
          link: "https://scholar.google.dk/citations?user=PU3PoksAAAAJ&hl=en",
          img: "id_rasmus_fensholt_bw.png",
        },
        {
          name: "Alexandre Poinso",
          desc: "Lead Data Scientist at Kayrros working on biomass monitoring using remote sensing techniques.",
          link: "",
          img: "id_alex_poinso_bw.png",
        },
        {
          name: "Alexandre d'Aspremont",
          desc: "Kayrros Co-Founder and Chief Scientist as well as Research Director at CNRS, in France.",
          link: "https://www.di.ens.fr/~aspremon/",
          img: "id_alex_aspremont_bw.png",
        },
        {
          name: "Claire Bonfils",
          desc: "Product Manager at Kayrros leading carbon sequestration and forest monitoring applications \
                        using remote sensing technologies.",
          link: "",
          img: "id_claire_bonfils_bw.png",
        },
      ],
    };
  },
};
</script>

<style lang="scss"></style>
