/* Biomass Data class
Handle the display of the fetched data (KPI and Graphs) */

export class BiomassData {
  constructor() {
    this.biomass_aggregated_country = []; //Biomass value per country per quarter From : biomass_aggregated_country.csv
    this.biomass_aggregated_global = []; //World Biomass value per quarter From : biomass_aggregated_world.csv
    this.smos_coverage_country = []; // SMOS Coverage per country From : SMOS_country_coverage.csv
    this.kpi = {
      //Data to render KPICountry component
      relative_change: "",
      relative_change_per_year: "",
      change: "",
      coverage: "",
    };
    this.overview_chart_data = []; // Data to render Overview Chart
    this.yearly_chart_data = []; // Data to render Yearly Chart
    this.unit = "Pg C"; // Unit for graph
  }

  /* Set then return KPI data 
 @param Country The selected country
 @param inbound The index of the inbound date 
 @param outbound The index of the outbound date 
 @param timespan The timespan between inbound and outbound date
 */
  getKPI(country, inbound, outbound, timespan) {
    if (country === "World") {
      this.unit = "Pg C";
      this.getWorldKPI(inbound, outbound, timespan);
      this.getCountryCoverage(country);
    } else {
      this.unit = "Tg C";
      this.getCountryKPI(country, inbound, outbound, timespan);
      this.getCountryCoverage(country);
    }
    return this.kpi;
  }

  /* Set then return KPI data for the world
 @param inbound The index of the inbound date 
 @param outbound The index of the outbound date 
 @param timespan The timespan between inbound and outbound date
 */
  getWorldKPI(inbound, outbound, timespan) {
    let data_inbound = this.getWorldData(inbound);
    let data_outbound = this.getWorldData(outbound);
    if (
      data_inbound.total_max_carbon_stock &&
      data_outbound.total_max_carbon_stock
    ) {
      let carbon_stock_inbound = parseInt(data_inbound.total_max_carbon_stock);
      let carbon_stock_outbound = parseInt(
        data_outbound.total_max_carbon_stock
      );
      let relative_change =
        ((carbon_stock_outbound - carbon_stock_inbound) /
          carbon_stock_inbound) *
        100;
      relative_change = relative_change.toFixed(1);
      let relative_change_per_year = relative_change / timespan;
      relative_change_per_year = relative_change_per_year.toFixed(1);
      let change = carbon_stock_outbound - carbon_stock_inbound;
      if (relative_change >= 0) {
        this.kpi.relative_change = "+ " + relative_change + " %";
        this.kpi.relative_change_per_year =
          "+ " + relative_change_per_year + " %";
      } else {
        this.kpi.relative_change = "- " + Math.abs(relative_change) + " %";
        this.kpi.relative_change_per_year =
          "- " + Math.abs(relative_change_per_year) + " %";
      }

      if (change > 0) {
        this.kpi.change = "+ " + Math.round(change);
      } else {
        this.kpi.change = "- " + Math.abs(Math.round(change));
      }
    }
  }

  /* Set then return KPI data for a specific country
   @param Country The selected country
 @param inbound The index of the inbound date 
 @param outbound The index of the outbound date 
 @param timespan The timespan between inbound and outbound date
 */
  getCountryKPI(country, inbound, outbound, timespan) {
    var data_inbound = this.getCountryData(country, inbound);
    var data_outbound = this.getCountryData(country, outbound);
    if (data_inbound.max_carbon_stock && data_outbound.max_carbon_stock) {
      let carbon_stock_inbound = parseInt(data_inbound.max_carbon_stock);
      let carbon_stock_outbound = parseInt(data_outbound.max_carbon_stock);
      let relative_change =
        ((carbon_stock_outbound - carbon_stock_inbound) /
          carbon_stock_inbound) *
        100;
      relative_change = relative_change.toFixed(1);
      let relative_change_per_year = relative_change / timespan;
      relative_change_per_year = relative_change_per_year.toFixed(1);
      let change = carbon_stock_outbound - carbon_stock_inbound;
      if (relative_change >= 0) {
        this.kpi.relative_change = "+ " + relative_change + " %";
        this.kpi.relative_change_per_year =
          "+ " + relative_change_per_year + " %";
      } else {
        this.kpi.relative_change = "- " + Math.abs(relative_change) + " %";
        this.kpi.relative_change_per_year =
          "- " + Math.abs(relative_change_per_year) + " %";
      }

      if (change > 0) {
        this.kpi.change = "+ " + change;
      } else {
        this.kpi.change = "- " + Math.abs(change);
      }
    }
  }

  /* Return biomass data for a specific quarter 
  @param date Date as formated : YYYY-QQ */
  getWorldData(date) {
    var data = this.biomass_aggregated_global.filter(
      (row) => row.date === date
    );
    if (data.length > 0) {
      return data[0];
    }
    return [];
  }

  /* Return country biomass data for a specific quarter 
  @param date Date as formated : YYYY-QQ */
  getCountryData(country, date) {
    var data = this.biomass_aggregated_country.filter(
      (row) => row.date === date && row["Country"] === country
    );
    if (data.length > 0) {
      return data[0];
    }
    return [];
  }

  /* 
    DEPRECATED : World data included in country coverage file
  Compute and return World Coverage given list of countries coverage */
  getWorldCoverage() {
    var total_coverage = 0;
    var count_country = 0;
    for (var i = 0; i < this.smos_coverage_country.length; i++) {
      if (this.smos_coverage_country[i].coverage_percent) {
        total_coverage += parseFloat(
          this.smos_coverage_country[i].coverage_percent
        );
        count_country++;
      }
    }
    this.kpi.coverage = parseInt(total_coverage / count_country) + " %";
  }

  /* Return specific country coverage 
  @param country Name of the country */
  getCountryCoverage(country) {
    var data = this.smos_coverage_country.filter(
      (row) => row.country === country
    );
    if (data.length > 0) {
      this.kpi.coverage = parseInt(data[0].coverage_percent) + " %";
    }
  }

  /* Build and return Overview Chart 
   @param inbound The index of the inbound date 
 @param outbound The index of the outbound date 
 @param country Name of the country */
  getOverviewChartData(country, inbound, outbound) {
    if (country === "World") {
      this.getWorldOverview(inbound, outbound);
      this.unit = "Pg C";
    } else {
      this.getCountryOverview(country, inbound, outbound);
      this.unit = "Tg C";
    }
    return this.overview_chart_data;
  }

  /* Return Biomass data of the world as a list per quarter in between inbound - outbound range
      @param inbound The index of the inbound date 
 @param outbound The index of the outbound date 
 */
  getWorldOverview(inbound, outbound) {
    var in_range = false;
    var data = [];
    for (var i = 0; i < this.biomass_aggregated_global.length; i++) {
      if (this.biomass_aggregated_global[i].date === inbound) {
        in_range = true;
      }
      if (in_range) {
        data.push([
          this.biomass_aggregated_global[i].date,
          parseFloat(
            parseFloat(
              this.biomass_aggregated_global[i].total_max_carbon_stock
            ).toFixed(1)
          ),
        ]);
      }
      if (this.biomass_aggregated_global[i].date === outbound) {
        in_range = false;
        break;
      }
    }
    this.overview_chart_data = data;
  }

  /* Return Biomass data of a country as a list per quarter in between inbound - outbound range
    @param country Name of the country
      @param inbound The index of the inbound date 
 @param outbound The index of the outbound date 
 */
  getCountryOverview(country, inbound, outbound) {
    var in_range = false;
    var data = [];
    let country_data = this.biomass_aggregated_country.filter(
      (row) => row["Country"] === country
    );
    for (var i = 0; i < country_data.length; i++) {
      if (country_data[i].date === inbound) {
        in_range = true;
      }
      if (in_range) {
        data.push([
          country_data[i].date,
          parseFloat(parseFloat(country_data[i].max_carbon_stock).toFixed(1)),
        ]);
      }
      if (country_data[i].date === outbound) {
        in_range = false;
        break;
      }
    }
    this.overview_chart_data = data;
  }

  /* Build and return Overview Chart 
 @param country Name of the country */
  getYearlyChartData(country) {
    if (country === "World") {
      this.getWorldYearly();
      this.unit = "Pg C";
    } else {
      this.getCountryYearly(country);
      this.unit = "Tg C";
    }
    return this.yearly_chart_data;
  }

  /* Return Biomass data of the world as a list per year of quarters value
  Format : Data[YYYY] =  [Q1,Q2,Q3,Q4]
   */
  getWorldYearly() {
    var data = {};
    for (var i = 0; i < this.biomass_aggregated_global.length; i++) {
      let row = this.biomass_aggregated_global[i];
      let current_year = row.date.split("-")[0];
      if (!data[current_year]) {
        data[current_year] = [];
      }
      data[current_year].push([
        row.date.split("-")[1],
        parseFloat(parseFloat(row.total_max_carbon_stock).toFixed(1)),
      ]);
    }
    this.yearly_chart_data = data;
  }

  /* Return Biomass data of the world as a list per year of quarters value
    @param country Name of the country
  Format : Data[YYYY] =  [Q1,Q2,Q3,Q4]
   */
  getCountryYearly(country) {
    var data = {};
    let country_data = this.biomass_aggregated_country.filter(
      (row) => row["Country"] === country
    );
    for (var i = 0; i < country_data.length; i++) {
      let row = country_data[i];
      let current_year = row.date.split("-")[0];
      if (!data[current_year]) {
        data[current_year] = [];
      }
      data[current_year].push([
        row.date.split("-")[1],
        parseFloat(parseFloat(row.max_carbon_stock).toFixed(1)),
      ]);
    }
    this.yearly_chart_data = data;
  }
}
