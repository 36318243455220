<template>
  <div class="content">
    <HeroHeader></HeroHeader>
    <Partners></Partners>
    <Introduction></Introduction>
    <TimerangeSeparator></TimerangeSeparator>
    <WorldMap></WorldMap>
    <CountrySeparator></CountrySeparator>
    <DataVisualization></DataVisualization>
    <Footer style="margin-top: 40px"></Footer>
  </div>
</template>

<script>
import HeroHeader from "@/components/HeroHeader.vue";
import Partners from "@/components/Partners.vue";
import Introduction from "@/components/Introduction.vue";
import TimerangeSeparator from "@/components/TimerangeSeparator.vue";
import CountrySeparator from "@/components/CountrySeparator.vue";
import WorldMap from "@/components/WorldMap.vue";
import DataVisualization from "@/components/DataVisualization.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    HeroHeader,
    Partners,
    Introduction,
    WorldMap,
    DataVisualization,
    Footer,
    TimerangeSeparator,
    CountrySeparator,
  },
  name: "Home",
};
</script>
