<template>
  <!-- OVERVIEW CHART -->
  <section class="chart_section">
    <div id="overview_chart"></div>
  </section>
</template>

<script>
import * as Highcharts from "highcharts/highcharts.js";
export default {
  props: ["values", "unit"],
  data() {
    return {
      chart: {},
    };
  },
  watch: {
    values() {
      /* Re-render graph on values change */
      if (this.values.length > 0) {
        this.drawChart();
        this.$forceUpdate();
      }
    },
  },
  methods: {
    drawChart() {
      var _this = this;
      this.chart = Highcharts.chart("overview_chart", {
        yAxis: {
          title: {
            text: "Biomass Carbon Stocks (in " + _this.unit + ")",
          },
        },

        xAxis: {
          tickInterval: 1,
          overflow: "allow",
          labels: {
            enabled: true,
            formatter: function () {
              /* Format xAxis to only display years */
              if (_this.values && _this.values[this.value][0].includes("Q1")) {
                return _this.values[this.value][0].split("-")[0];
              } else {
                return "";
              }
            },
          },
        },
        tooltip: {
          useHTML: true,
          backgroundColor: null,
          borderWidth: 0,
          shadow: false,
          formatter: function () {
            /* Format tooltip with custom html */
            return (
              "<div class='custom_tooltip' style='background-color:" +
              this.series.color +
              ";'>Quarter : <b>" +
              _this.values[this.x][0] +
              "</b><br> Biomass Carbon Stock : <b>" +
              this.y +
              "</b></div>"
            );
          },
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
          },
        },
        legend: {
          enabled: false,
        },
        series: [
          {
            name: "Biomass Carbon Stocks",
            data: this.values,
            lineColor: "#80c7d5",
            color: "#80c7d5",
          },
        ],

        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {},
            },
          ],
        },
      });
    },
  },
  mounted() {
    this.drawChart();
  },
};
</script>

<style lang="scss"></style>
