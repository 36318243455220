<template>
  <div class="content">
    <Header></Header>
    <section id="our-methods">
      <b-container>
        <b-row>
          <b-col>
            <h3
              class="section_title"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              ESA Climate Change Initiative Project CCI-RECCAP-2
            </h3>
            <hr class="sep_title" />
            <p>
              <b-icon icon="circle-fill" class="bullet"></b-icon> LVOD-IB
              products have been prepared by INRAE Bordeaux and LSCE and were
              used in the ESA Climate Change Initiative Project CCI-RECCAP-2
              which supports and accelerates the analysis of regional carbon
              budgets based on the results of data-driven models and
              process-oriented Global Dynamic Vegetation Models. <br />
              <b-icon icon="circle-fill" class="bullet"></b-icon> This project
              contributes to the second phase of the international RECCAP
              initiative aiming at a global synthesis of land and ocean carbon
              fluxes, and coordinated by the
              <a
                href="https://www.globalcarbonproject.org/Reccap/index.htm"
                target="_blank"
                >Global Carbon Project</a
              >. The LVOD-IB products have also been used in the
              <a
                href="https://www.aber.ac.uk/en/dges/research/earth-observation-laboratory/research/cci-biomass/"
                target="_blank"
                >ESA Climate Change initiative Project CCI-BIOMASS</a
              >. <br />
              <b-icon icon="circle-fill" class="bullet"></b-icon> The primary
              purpose of this project is to use long-term and recent Earth
              Observation datasets to generate global estimates of Essential
              Climate Variables (ECVs), primarily for use in climate change
              science. <br />
              <b-icon icon="circle-fill" class="bullet"></b-icon> The CCI
              BIOMASS project, led by Aberystwyth University and involving 10
              additional international (European) partners, is specifically
              tasked with advancing methodologies for retrieving vegetation
              (primarily above-ground) biomass and applying these to generate
              global biomass maps for three epochs (2007-2010, 2017/18 and
              2018/19) as well as biomass change maps.
            </p>
            <div class="sep-30"></div>
            <h3
              class="section_title"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              L-VOD data
            </h3>
            <hr class="sep_title" />
            <p>
              Satellite-based vegetation optical depth at L-band (L-VOD) related
              to above-ground biomass carbon and water content within vegetation
              has been widely used as a proxy of vegetation biomass for
              monitoring changes in carbon stocks (<a
                href="https://doi.org/10.1038/nclimate2581"
                target="_blank"
              >
                Liu et al., 2013 </a
              >;
              <a
                href="https://www.sciencedirect.com/science/article/pii/S0034425720306118"
                target="_blank"
              >
                Wigneron et al., 2020) </a
              >.
            </p>
            <p>
              L-VOD data at L-band (~1.4 GHz) were retrieved by the passive
              microwave Soil Moisture and Ocean Salinity (SMOS) satellite at a
              spatial resolution of 25 x 25 km and an original temporal
              resolution of one day using the LMEB model.
            </p>
            <p>
              Compared to other shorter wavelength VOD products (e.g.,
              C/X/K-VOD), L-VOD data is proven to be more sensitive to the woody
              component of the vegetation and does not saturate at high biomass,
              because the low frequency microwaves can penetrate deeper within
              the forest canopy (<a
                href="https://bg.copernicus.org/articles/15/4627/2018/"
                target="_blank"
              >
                Rodríguez-Fernández et al., 2018 </a
              >).The AGB changes presented in this site are estimated based on a
              SMOS L-VOD data set (referred to as SMOS-IC, Version 2) which has
              been developed by the INRAE Bordeaux Remote Sensing Laboratory
              (Wigneron et al., 2021), which is an update of the product in
              version V105 (Fernandez-Moran et al., 2017).
            </p>
            <div class="sep-30"></div>
            <h3
              class="section_title"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              New filtering method for L-VOD data
            </h3>
            <hr class="sep_title" />
            <b-row>
              <b-col md="12">
                <p>
                  Previous studies applied a simple method to filter L-VOD data
                  using the root mean square of the measured and simulated
                  brightness temperature (referred to as RMSE-TB). The good
                  performance of that filtering method has been confirmed for
                  the pan-tropical regions (
                  <a
                    href="https://doi.org/10.1038/s41477-019-0478-9"
                    target="_blank"
                  >
                    Fan et al. , 2019</a
                  >;
                  <a
                    href="https://www.sciencedirect.com/science/article/pii/S0034425720306118"
                    target="_blank"
                  >
                    Wigneron et al., 2020) </a
                  >). <br />However, for some northern hemisphere regions where
                  L-VOD data was seriously disturbed by Radio Frequency
                  Interference (RFI) in a non-stationary way, this simple
                  filtering method has limitations. Thus, we applied here a
                  stricter filtering method for selecting the “best” quality
                  data from both ascending and descending orbits to extend the
                  L-VOD data to the extratropical regions that have areas
                  affected by RFI.<br />
                  After this strict filtering, we fit and reconstruct L-VOD data
                  using the time series curve fitting method from Thoning et al.
                  (1989), which is used to filter, smooth and reconstruct
                  atmospheric CO2 from discrete air samples by NOAA. The details
                  of filtering, fitting and reconstruction processes are
                  described in Yang et al. in preparation.
                </p>
              </b-col>
            </b-row>
            <div class="sep-30"></div>
            <h3
              class="section_title"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Extrapolation of invalid pixels
            </h3>
            <hr class="sep_title" />
            <b-row>
              <b-col md="12">
                <p>
                  Due to Radio-Frequency Interferences (RFI), many L-VOD values
                  were filtered out, especially before 2014-2015. This leads to
                  a poor spatial coverage in some areas strongly affected by the
                  RFI. Instead of leaving these areas out, we were able to
                  reconstruct the data when affected by the RFI and filtered
                  out. To do so, an average temporal change index was built for
                  each country, using "clean" data. Then, starting from the
                  known values of a given pixel, this index was applied to
                  extrapolate the unknown values of this pixel. Results obtained
                  after this extrapolation step are consistent with the net
                  global sink of forests (~1.1PG C) provided by inventories for
                  the decade of the 2000s (
                  <a
                    href="http://doi.org/10.1126/science.1201609"
                    target="_blank"
                  >
                    Pan et al., 2011 </a
                  >).
                </p>
              </b-col>
            </b-row>
            <div class="sep-30"></div>
            <h3
              class="section_title"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Above-ground biomass (AGB) derived from L-VOD
            </h3>
            <hr class="sep_title" />
            <p>
              One AGB map from GlobBiomass dataset (<a
                href="https://essd.copernicus.org/articles/13/3927/2021/essd-13-3927-2021-discussion.html"
                target="_blank"
              >
                Santoro et al., 2020 </a
              >) has been used to transfer L-VOD to AGB data.
            </p>
          </b-col>
        </b-row>
      </b-container>
      <b-container>
        <b-row>
          <b-col>
            <div class="sep-30"></div>
            <h3
              class="section_title"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Calculation of average AGB over four-months period (quarters)
            </h3>
            <hr class="sep_title" />
            <b-row>
              <b-col md="12">
                <p>
                  AGB changes (∆AGB) was calculated as the difference of AGB
                  between different quarters. The seasonal cycle which is
                  sensitive to water content was removed from the AGB time
                  series decomposition using the Thoning et al. (1989) model,
                  resulting in a « smoothed trend » weekly curve defined by a
                  2nd order polynomial function with low-pass filtered residuals
                  that capture quarterly inter-annual variability. ∆AGB is
                  estimated using different month-centered quarterly
                  averages.<br />
                  We do not show ∆AGB between quarters for intervals shorter
                  than two years, to prevent aliasing biomass carbon change with
                  vegetation water content changes ∆AGB was calculated using the
                  ascending- and decreasing-merged (hereafter referred to as ASC
                  & DESC-merged) data, but only ascending (ASC) data and only
                  descending (DESC) data were used for correction. The data from
                  ascending orbit were measured in the morning at 6 am, while
                  the data from descending orbit were measured in the afternoon
                  at 6 pm. The AGB from ascending and descending orbits should
                  be very close, because carbon does not change on diurnal
                  scales. <br />Thus, if the AGB from ascending and descending
                  orbits have large difference, the possible reason is either
                  ASC data or DESC data were disturbed by RFI. The data
                  disturbed by RFI should show a smaller value than the actual
                  value, likely leading to underestimation of AGB using ASC &
                  DESC-merged data. Thus, we compared or quarterly ASC AGB and
                  DESC AGB. If their difference is greater than 10% of their
                  mean, we use the highest of the ASC and DESC data rather than
                  ASC & DESC-merged data to calculate ∆AGB. <br />If both ASC
                  and DESC data are contaminated by RFI, no data is presented
                  (e.g. in China) which defines the coverage parameter (the
                  fraction of 25 km grid cells that are excluded due to RFI in a
                  given country).
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
      <div id="references">
        <b-container>
          <b-row>
            <b-col>
              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-1 variant="info">
                      <b-icon icon="caret-right" class="icon_expand"></b-icon>
                      <b-icon icon="caret-up" class="icon_collapse"></b-icon>
                      REFERENCES FOR L-VOD</b-button
                    >
                  </b-card-header>
                  <b-collapse
                    id="accordion-1"
                    visible
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-card-text>
                        <p>
                          <span class="puce_reference">[1]</span> Wigneron,
                          J.-P., Li, X. et al. SMOS-IC data record of soil
                          moisture and L-VOD: Historical development,
                          applications and perspectives. Remote Sensing of
                          Environment. 254., 2021.
                          <a
                            href="https://doi.org/10.1016/j.rse.2020.112238"
                            target="_blank"
                          >
                            https://doi.org/10.1016/j.rse.2020.112238</a
                          ><br />
                          <span class="puce_reference">[2]</span> Li, X.,
                          Wigneron, J.P., et al. Global-scale assessment and
                          inter-comparison of recently developed/reprocessed
                          microwave satellite vegetation optical depth products.
                          Remote Sens. Environ. 253.
                          <a
                            href="https://doi.org/10.1016/j.rse.2020.112208"
                            target="_blank"
                          >
                            https://doi.org/10.1016/j.rse.2020.112208
                          </a>
                          <br />
                          <span class="puce_reference">[3]</span>
                          Fernandez-Moran R. et al. "SMOS-IC: An Alternative
                          SMOS Soil Moisture and Vegetation Optical Depth
                          Product", Remote Sensing, 9, 457;
                          <a
                            href="https://doi:10.3390/rs9050457"
                            target="_blank"
                          >
                            https://doi:10.3390/rs9050457 </a
                          >, 2017. <br />

                          <span class="puce_reference">[4]</span> INRAE Bordeaux
                          Remote Sensing Laboratory:
                          <a
                            href="https://ib.remote-sensing.inrae.fr/"
                            target="_blank"
                          >
                            https://ib.remote-sensing.inrae.fr/
                          </a>
                        </p>
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-2 variant="info">
                      <b-icon icon="caret-right" class="icon_expand"></b-icon>
                      <b-icon icon="caret-up" class="icon_collapse"></b-icon>
                      REFERENCES FOR L-VOD APPLIED TO CARBON STOCKS MONITORING
                    </b-button>
                  </b-card-header>
                  <b-collapse
                    id="accordion-2"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-card-text>
                        <p>
                          <span class="puce_reference">[5]</span> Qin, Y. et al.
                          Carbon loss from forest degradation exceeds that from
                          deforestation in the Brazilian Amazon. Nature Climate
                          Change. 11, 442-448 (2021).
                          <a
                            href="https://doi.org/10.1038/s41558-021-01026-5"
                            target="_blank"
                            >https://doi.org/10.1038/s41558-021-01026-5</a
                          ><br />
                          <span class="puce_reference">[6]</span> Fan, L.,
                          Wigneron, J.-P., Ciais, P., Chave, J., Brandt, M.,
                          Fensholt, R., et al. (2019). Satellite-observed
                          pantropical carbon dynamics. Nature Plants.
                          <a
                            target="_blank"
                            href="https://doi.org/10.1038/s41477-019-0478-9"
                            >https://doi.org/10.1038/s41477-019-0478-9</a
                          ><br />
                          <span class="puce_reference">[7]</span> Wigneron,
                          J.-P., Fan, L., Ciais, P., Bastos, A., Brandt, M.,
                          Chave, J., et al. (2020). Tropical forests did not
                          recover from the strong 2015-2016 El Niño event.
                          Science Advances, 6(6).
                          <a
                            target="_blank"
                            href="https://doi.org/10.1126/sciadv.aay4603"
                            >https://doi.org/10.1126/sciadv.aay4603</a
                          >
                          <br />
                          <span class="puce_reference">[8]</span> Tong, X.,
                          Brandt, M., Yue, Y., Ciais, P., Rudbeck Jepsen, M.,
                          Penuelas, J., et al. (2020). Forest management in
                          southern China generates short term extensive carbon
                          sequestration. Nature Communications, 11(1), 129.
                          <a
                            target="_blank"
                            href="https://doi.org/10.1038/s41467-019-13798-8"
                            >https://doi.org/10.1038/s41467-019-13798-8</a
                          ><br />
                          <span class="puce_reference">[9]</span> Brandt M., P
                          Hiernaux, K Rasmussen, CJ Tucker et al., Changes in
                          rainfall distribution promote woody foliage production
                          in the Sahel, Communications Biology, 2:133, 2019,
                          <a
                            target="_blank"
                            href="https://doi.org/10.1038/s42003-019-0383-9"
                            >https://doi.org/10.1038/s42003-019-0383-9</a
                          ><br />
                          <span class="puce_reference">[10]</span> Tian F.,
                          J.-P. Wigneron, P. Ciais, J. Chave, et al., Coupling
                          of ecosystem-scale plant water storage and leaf
                          phenology observed by satellite, Nature Ecology &
                          Evolution, Vol 2, Spt. 2018, 1428–1435,
                          <a
                            target="_blank"
                            href="https://doi.org/10.1038/s41559-018-0630-3"
                            >https://doi.org/10.1038/s41559-018-0630-3</a
                          >, 2, 1428–1435, 2018<br />
                          <span class="puce_reference">[11]</span> Brandt, M.
                          Wigneron J-P, et al. Satellite passive microwaves
                          reveal recent climate-induced carbon losses in African
                          drylands. Nature Ecology & Evolution. 2, 827-835
                          (2018).
                          <a
                            href="https://doi.org/10.1038/s41559-018-0530-6"
                            target="_blank"
                            >https://doi.org/10.1038/s41559-018-0530-6</a
                          ><br />
                        </p>
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: { Header, Footer },
};
</script>

<style lang="scss"></style>
