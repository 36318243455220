<template>
  <div>
    <vue-slider
      v-model="selection"
      :marks="marks"
      :min="0"
      :max="41"
      :min-range="8"
      :interval="1"
      :enable-cross="false"
      :tooltip-formatter="formated_tooltip"
      @change="updateTimeRange"
    ></vue-slider>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import { TimeRange } from "@/classes/TimeRange.js";
import "vue-slider-component/theme/antd.css";
export default {
  components: {
    VueSlider,
  },
  data() {
    return {
      timerange: new TimeRange(),
      selection: [],
      marks: {},
      formated_tooltip: (v) => this.timerange.getTooltip(v),
    };
  },
  watch: {
    "$store.state.timerange.selection": function () {
      if (this.selection !== this.$store.state.timerange.selection) {
        this.selection = this.$store.state.timerange.selection;
      }
    },
  },
  methods: {
    /* Compute timerange data and commit to local storage */
    updateTimeRange() {
      this.timerange.selection = this.selection;
      this.timerange.setReadableDate();
      this.timerange.setBoundDates();
      this.timerange.setTimeSpan();
      this.$store.commit("updateTimerange", this.timerange);
    },
  },
  created() {
    this.marks = this.timerange.steps;
    this.selection = this.timerange.selection;
    this.timerange.setReadableDate();
    this.timerange.setBoundDates();
    this.timerange.setTimeSpan();
    this.$store.commit("updateTimerange", this.timerange);
  },
};
</script>

<style lang="scss"></style>
