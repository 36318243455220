<template>
  <!-- KPI DATA -->
  <section id="kpi">
    <b-container>
      <b-row>
        <b-col>
          <h3 class="row_title" data-aos="fade-up" data-aos-duration="800">
            Biomass Carbon Stocks
          </h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p class="help">
            <b-icon icon="question-circle-fill" aria-hidden="true"></b-icon>
            The metrics below show the above-ground biomass relative and
            absolute changes between two quarters. You can adjust the time range
            and the country selected with the filters above. The relative change
            per year is computed as the relative change divided by the number of
            years (e.g. 1.5 for 1 year and half). The coverage indicates the
            averaged area of the country covered with valid pixels.
          </p>
          <p class="align_center" style="font-size: 0.8rem">
            from {{ $store.state.timerange.readable_date }}
          </p>
        </b-col>
      </b-row>
      <b-row data-aos="fade" data-aos-duration="1000">
        <b-col md="12">
          <div
            class="kpi_card"
            v-bind:class="[
              values.relative_change.includes('+')
                ? 'kpi_card_positive'
                : 'kpi_card_negative',
            ]"
          >
            <p class="kpi_value">{{ values.relative_change }}</p>
            <p class="kpi_desc">Relative Change</p>
          </div>
        </b-col>
        <b-col md="12">
          <div
            class="kpi_card"
            v-bind:class="[
              values.relative_change_per_year.includes('+')
                ? 'kpi_card_positive'
                : 'kpi_card_negative',
            ]"
          >
            <p class="kpi_value">{{ values.relative_change_per_year }}</p>
            <p class="kpi_desc">Relative change per year</p>
          </div>
        </b-col>
        <b-col md="12">
          <div
            class="kpi_card"
            v-bind:class="[
              values.change.includes('+')
                ? 'kpi_card_positive'
                : 'kpi_card_negative',
            ]"
          >
            <p class="kpi_value">{{ values.change }} {{ unit }}</p>
            <p class="kpi_desc">Change</p>
          </div>
        </b-col>
        <b-col md="12">
          <div class="kpi_card">
            <p class="kpi_value">{{ values.coverage }}</p>
            <p class="kpi_desc">Coverage</p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  props: ["values", "unit"],
};
</script>

<style lang="scss"></style>
