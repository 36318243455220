<template>
  <!-- NAVBAR -->
  <b-navbar
    v-bind:class="[scrolled ? 'page-scrolled' : '']"
    id="navbar"
    toggleable="lg"
    fixed="top"
  >
    <b-navbar-brand href="/">
      <p>Biomass Carbon Monitor</p>
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item href="/">Home</b-nav-item>
        <b-nav-item href="/who-we-are">Who we are</b-nav-item>
        <b-nav-item href="/methods">Methods</b-nav-item>
        <b-nav-item href="https://carbonmonitor.org/" target="_blank"
          ><img
            src="../assets/img/carbonmonitor.png"
            class="icon_carbon_monitor"
          />Carbon Monitor</b-nav-item
        >
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  props: ["scrolled"],
  data() {
    return {};
  },
};
</script>

<style lang="scss"></style>
