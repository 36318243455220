<template>
  <!-- PRODUCT INTRODUCTION SECTION -->
  <section id="introduction">
    <b-container>
      <b-row>
        <b-col md="12">
          <h2 class="section_title" data-aos="fade-up" data-aos-duration="800">
            BIOMASS CARBON MONITOR
          </h2>
          <hr class="sep_title" />
          <p
            class="introduction_text"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Plants remove globally carbon dioxide from the atmosphere and store
            carbon, mainly in biomass. The biomass stocks are vulnerable to
            human and climate disturbances such as fires, harvest, deforestation
            and degradation. Biomass Carbon Monitor is an international project
            providing for the first time regularly updated, science-based
            estimates of quarterly changes in above-ground biomass carbon,
            referred to as biomass carbon stocks, using L-band passive microwave
            remote sensing of Vegetation Optical Depth (L-VOD) from the SMOS
            satellite of ESA. The data reveal losses and gains of biomass over
            the last decade at 25 x 25 km quarterly resolution. The data are
            displayed at country scale. For gridded fields please
            <a href="https://kayrros.com/contact-us/" target="_blank"
              >contact us</a
            >.
          </p>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss"></style>
