<template>
  <!-- SEPARATOR WITH TIMERANGE SLIDER -->
  <section id="timerange-separator">
    <b-container>
      <b-row>
        <b-col>
          <h2
            class="section_title in_sep"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Biomass Carbon Stocks Changes
          </h2>
          <h3 class="section_subtitle in_sep">
            {{ $store.state.timerange.readable_date }}
          </h3>
          <hr class="sep_title" />
          <p class="sep_desc">
            The map shows the above-ground biomass changes between two quarters.
            The value associated with each pixel is computed as the maximum on a
            1-year rolling window. The maps are produced on a 1-degree cell grid
            resolution using SMOS L-VOD data that are available at a 25km
            resolution. You can adjust the time range with the filter below,
            there is a minimum limit of 2 years between the start and the end.
          </p>
          <p class="label_field_range"><b>Select a time range</b></p>
          <TimeRange></TimeRange>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import TimeRange from "@/components/visualization/TimeRange.vue";
export default {
  components: { TimeRange },
};
</script>

<style lang="scss" scoped></style>
