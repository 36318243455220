<template>
  <!-- SEPARATOR WITH COUNTRY SELECT -->
  <section id="country-separator">
    <b-container>
      <b-row>
        <b-col md="4">
          <p class="select_text">Select a country</p>
        </b-col>
        <b-col md="4">
          <DropdownCountries style="display: inline"></DropdownCountries>
        </b-col>
        <b-col md="4">
          <p class="date_text">
            from {{ $store.state.timerange.readable_date }}
          </p>
        </b-col>
      </b-row>
      <b-row style="margin-top: 30px">
        <b-col md="4">
          <p class="select_text">Select a time range</p>
        </b-col>
        <b-col md="8">
          <TimeRange style="padding: 0 10px"></TimeRange>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import TimeRange from "@/components/visualization/TimeRange.vue";
import DropdownCountries from "@/components/visualization/DropdownCountries.vue";
export default {
  components: { DropdownCountries, TimeRange },
};
</script>

<style lang="scss" scoped></style>
