<template>
  <!-- YEARLY CHART -->
  <section class="chart_section">
    <div id="yearly_chart"></div>
  </section>
</template>

<script>
import * as Highcharts from "highcharts/highcharts.js";
export default {
  props: ["values", "unit"],
  data() {
    return {
      chart: {},
      series: [],
      categories: ["Q1", "Q2", "Q3", "Q4"],
      colors: [
        "#002642",
        "#840032",
        "#e59500",
        "#a77b7b",
        "#02040f",
        "#028090",
        "#6cb164",
        "#456990",
        "#f45b69",
        "#41ead4",
      ],
    };
  },
  watch: {
    values() {
      if (this.values.length > 0) {
        this.buildSeries();
        this.drawChart();
        this.$forceUpdate();
      }
    },
  },
  methods: {
    /* Build a data serie for each year, with pre-defined linecolor */
    buildSeries() {
      this.series = [];
      var index_color = 0;
      for (var [year, value] of Object.entries(this.values)) {
        if (!year.includes("2010")) {
          if (index_color > this.colors.length - 1) {
            index_color = 0;
          }
          this.series.push({
            name: year,
            data: value,
            color: this.colors[index_color],
            lineColor: this.colors[index_color],
          });
          index_color++;
        }
      }
    },
    drawChart() {
      this.$forceUpdate();
      var _this = this;
      this.chart = Highcharts.chart("yearly_chart", {
        yAxis: {
          title: {
            text: "Biomass Carbon Stocks (in " + _this.unit + ")",
          },
        },

        xAxis: {
          tickInterval: 1,
          overflow: "allow",
          categories: _this.categories,
          labels: {
            enabled: true,
          },
        },
        tooltip: {
          useHTML: true,
          backgroundColor: null,
          borderWidth: 0,
          shadow: false,
          formatter: function () {
            return (
              "<div class='custom_tooltip' style='background-color:" +
              this.series.color +
              ";'><b>" +
              this.series.name +
              "-" +
              this.x +
              "</b><br> Biomass Carbon Stock : <b>" +
              this.y +
              "</b></div>"
            );
          },
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
          },
        },
        legend: {
          enabled: true,
        },
        series: _this.series,

        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {},
            },
          ],
        },
      });
    },
  },
  mounted() {
    this.buildSeries();
    this.drawChart();
  },
};
</script>

<style lang="scss"></style>
