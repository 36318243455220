<template>
  <!-- <div>{{ member }}</div> -->
  <div class="team_card" data-aos="fade" data-aos-duration="1000">
    <b-row class="inner_team_card">
      <b-col md="4" class="team_card_col">
        <img
          class="team_img"
          :src="require('../assets/img/team/' + member.img)"
          alt=""
        />
      </b-col>
      <b-col md="8" class="team_card_col team_card_col_content">
        <p class="team_name">{{ member.name }}</p>
        <p class="team_desc">{{ member.desc }}</p>
        <a
          class="team_link"
          target="_blank"
          v-if="member.link.length > 0"
          :href="member.link"
        >
          <b-button>More info</b-button>
        </a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ["member"],
};
</script>

<style lang="scss"></style>
