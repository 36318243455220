<template>
  <!-- PARTNERS BANNER SECTION -->
  <section id="partners_banner">
    <b-container>
      <b-row>
        <b-col md="3" class="partner_container">
          <a href="https://kayrros.com" target="_blank">
            <img
              class="partners_logo"
              src="../assets/img/partners/logo_kayrros_bw.png"
              alt="Logo Kayrros"
            />
          </a>
        </b-col>
        <b-col md="3" class="partner_container">
          <a href="https://www.lsce.ipsl.fr/" target="_blank">
            <img
              class="partners_logo"
              src="../assets/img/partners/logo_lsce_bw.png"
              alt="Logo LSCE"
            />
          </a>
        </b-col>
        <b-col md="3" class="partner_container">
          <a href="https://www.inrae.fr/" target="_blank">
            <img
              class="partners_logo"
              src="../assets/img/partners/logo_inrae_bw.png"
              alt="Logo INRAE"
            />
          </a>
        </b-col>
        <b-col md="3" class="partner_container">
          <a
            href="https://climate.esa.int/en/projects/reccap-2/"
            target="_blank"
          >
            <img
              class="partners_logo"
              src="../assets/img/partners/logo_reccap2_bw.png"
              alt="Logo RECCAP2"
            />
          </a>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss"></style>
