/* Class countries
Used for Dropdown selector */
export class Countries {
  constructor() {
    this.list = [
      "Afghanistan",
      "Albania",
      "Algeria",
      "Angola",
      "Argentina",
      "Armenia",
      "Australia",
      "Austria",
      "Azerbaijan",
      "Bangladesh",
      "Belarus",
      "Belgium",
      "Belize",
      "Benin",
      "Bhutan",
      "Bolivia, Plurinational State of",
      "Bosnia and Herzegovina",
      "Botswana",
      "Brazil",
      "Brunei Darussalam",
      "Bulgaria",
      "Burkina Faso",
      "Burundi",
      "Cambodia",
      "Cameroon",
      "Canada",
      "Central African Republic",
      "Chad",
      "Chile",
      "Colombia",
      "Congo",
      "Congo, The Democratic Republic of the",
      "Costa Rica",
      "Cote d'Ivoire",
      "Croatia",
      "Cuba",
      "Czech Republic",
      "Denmark",
      "Djibouti",
      "Dominican Republic",
      "Ecuador",
      "Egypt",
      "El Salvador",
      "Equatorial Guinea",
      "Eritrea",
      "Estonia",
      "Ethiopia",
      "Fiji",
      "Finland",
      "France",
      "French Guiana",
      "Gabon",
      "Gambia",
      "Georgia",
      "Germany",
      "Ghana",
      "Greece",
      "Greenland",
      "Guatemala",
      "Guinea",
      "Guinea-Bissau",
      "Guyana",
      "Haiti",
      "Honduras",
      "Hungary",
      "Iceland",
      "India",
      "Indonesia",
      "Iran, Islamic Republic of",
      "Iraq",
      "Ireland",
      "Israel",
      "Italy",
      "Jamaica",
      "Japan",
      "Jordan",
      "Kazakhstan",
      "Kenya",
      "Korea, Democratic People's Republic of",
      "Korea, Republic of",
      "Kuwait",
      "Kyrgyzstan",
      "Lao People's Democratic Republic",
      "Latvia",
      "Lesotho",
      "Liberia",
      "Libyan Arab Jamahiriya",
      "Lithuania",
      "Luxembourg",
      "Macedonia",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Mali",
      "Mauritania",
      "Mexico",
      "Moldova, Republic of",
      "Mongolia",
      "Montenegro",
      "Morocco",
      "Mozambique",
      "Myanmar",
      "Namibia",
      "Nepal",
      "Netherlands",
      "New Zealand",
      "Nicaragua",
      "Niger",
      "Nigeria",
      "Norway",
      "Oman",
      "Pakistan",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines",
      "Poland",
      "Portugal",
      "Puerto Rico",
      "Qatar",
      "Romania",
      "Russian Federation",
      "Rwanda",
      "Saudi Arabia",
      "Senegal",
      "Serbia",
      "Sierra Leone",
      "Slovakia",
      "Slovenia",
      "Solomon Islands",
      "Somalia",
      "Southeast China",
      "South Africa",
      "South Sudan",
      "Spain",
      "Sri Lanka",
      "Sudan",
      "Suriname",
      "Swaziland",
      "Sweden",
      "Switzerland",
      "Syrian Arab Republic",
      "Taiwan",
      "Tajikistan",
      "Tanzania, United Republic of",
      "Thailand",
      "Timor-Leste",
      "Togo",
      "Tunisia",
      "Turkey",
      "Turkmenistan",
      "Uganda",
      "Ukraine",
      "United Arab Emirates",
      "United Kingdom",
      "United States of America",
      "Uruguay",
      "Uzbekistan",
      "Venezuela, Bolivarian Republic of",
      "Viet Nam",
      "Western Sahara",
      "Yemen",
      "Zambia",
      "Zimbabwe",
    ];
    this.exclusion_list = [
      "Gambia",
      "Greece",
      "Greenland",
      "Jamaica",
      "Jordan",
      "Syria",
      "Turkey",
      "Albania",
      "Armenia",
      "Bahamas",
      "Belgium",
      "Belize",
      "Bolivia",
      "Bhutan",
      "Bosnia and Herzegovina",
      "Brunei",
      "Burundi",
      "Costa Rica",
      "Cyprus",
      "Djibouti",
      "Dominican Rep.",
      "El Salvador",
      "Equatorial Guinea",
      "Fiji",
      "French Guiana",
      "Guinea-Bissau",
      "Haiti",
      "Israel",
      "Kosovo",
      "Kuwait",
      "Lebanon",
      "Lesotho",
      "Macedonia",
      "Moldova",
      "Montenegro",
      "New Caledonia",
      "Palestine",
      "Panama",
      "Puerto Rico",
      "Qatar",
      "Rwanda",
      "Sierra Leone",
      "Slovenia",
      "Solomon Islands",
      "Sri Lanka",
      "Swaziland",
      "Switzerland",
      "Taiwan",
      "Timor-Leste",
      "Togo",
      "Trinidad and Tobago",
      "Vanuatu",
      "Western Sahara",
    ];
    this.dropdown_list = [];
  }

  /* Set dropdown list by removing excluded country */
  setDropdownList() {
    this.dropdown_list.push("World");
    for (var i = 0; i < this.list.length; i++) {
      if (!this.exclusion_list.includes(this.list[i])) {
        this.dropdown_list.push(this.list[i]);
      }
    }
  }

  buildFromTabularData(data) {
    if (data || data.length > 0) {
      var temp_list = [];
      for (var i = 0; i < data.length; i++) {
        temp_list.push(data[i].country);
      }
      temp_list.sort();
      this.dropdown_list.push("World");
      for (i = 0; i < temp_list.length; i++) {
        if (temp_list[i] !== "World") {
          this.dropdown_list.push(temp_list[i]);
        }
      }
    }
    return this.dropdown_list;
  }
}
