/* Class Timerange
Handle timerange selector */

export class TimeRange {
  constructor() {
    this.selection = [0, 41];
    this.steps = {
      0: "2011",
      4: "2012",
      8: "2013",
      12: "2014",
      16: "2015",
      20: "2016",
      24: "2017",
      28: "2018",
      32: "2019",
      36: "2020",
      40: "2021",
    };
    this.years = [
      "2011",
      "2012",
      "2013",
      "2014",
      "2015",
      "2016",
      "2017",
      "2018",
      "2019",
      "2020",
      "2021",
    ];
    this.quarters = ["Q1", "Q2", "Q3", "Q4"];
    this.readable_date = "";
    this.inbound_date = "";
    this.outbound_date = "";
    this.timespan = 0;
  }
  /* 
  Return a formated text for the selector tooltip 
  Format : YYYY-QQ
  @param value : The index value of the selection 
  */
  getTooltip(value) {
    return this.years[Math.floor(value / 4)] + "-" + this.quarters[value % 4];
  }

  /* 
  Set a readable date format for the selection
  Format : MMM1 YYYY1 to MMM2 YYYY2 
  */
  setReadableDate() {
    this.readable_date =
      this.getDateFromStep(this.selection[0]) +
      " to " +
      this.getDateFromStep(this.selection[1]);
  }

  /* 
  Set a inbound and outbound date format for the selection
  Format : YYYY-QQ
  */
  setBoundDates() {
    this.inbound_date =
      this.years[Math.floor(this.selection[0] / 4)] +
      "-" +
      this.quarters[this.selection[0] % 4];
    this.outbound_date =
      this.years[Math.floor(this.selection[1] / 4)] +
      "-" +
      this.quarters[this.selection[1] % 4];
  }

  /* 
  Return a readable date format for the inbound or outbound date
  Format : MMM YYYY
  @param value : The index value of the selection 
  */
  getDateFromStep(s) {
    var response = "";
    var quarter = s % 4;
    switch (quarter) {
      case 0:
        response += "Jan-Mar ";
        break;
      case 1:
        response += "Apr-Jun ";
        break;
      case 2:
        response += "Jul-Sep ";
        break;
      case 3:
        response += "Oct-Dec ";
        break;
      default:
        break;
    }
    response += this.years[Math.floor(s / 4)];
    return response;
  }

  /* Set the timespan between inbound and outbound date */
  setTimeSpan() {
    this.timespan = (this.selection[1] - this.selection[0]) / 4;
  }
}
