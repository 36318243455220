<template>
  <section id="footer">
    <b-container>
      <b-row>
        <b-col>
          <p class="align_center">
            <a href="https://kayrros.com/contact-us/" target="_blank"
              >Contact</a
            >
            <span class="primary_color"><b> | </b></span>
            <b-button v-b-modal.modal-disclaimer>Disclaimer</b-button>
            <!-- Add a scroll to top button -->
            <b-button class="btn-scroll-top" @click="scrollToTop()"
              ><b-icon icon="arrow-up"></b-icon
            ></b-button>
          </p>
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="modal-disclaimer"
      title="Disclaimer"
      hide-header-close
      cancel-disabled
      ok-only
    >
      <p>
        The data are generated based on peer reviews articles and methods and
        may be subject to revisions and changes that will be documented on a
        best effort basis. Data have also limitations depending on their
        application purpose, which is under each user responsibility. For
        questions please
        <a
          class="primary_color"
          href="https://kayrros.com/contact-us/"
          target="_blank"
          >contact us</a
        >.
      </p>
    </b-modal>
  </section>
</template>

<script>
export default {
  methods: {
    scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style lang="scss"></style>
